import axios from "axios";

interface Headers{
    'Content-Type': string
}
export let headers: Headers = {
    'Content-Type': 'application/json',
};

export const API_INSTANCE = axios.create({
    baseURL: 'https://g0jrxuxzuh.execute-api.eu-west-1.amazonaws.com/v1',
    headers
})
