// @ts-ignore
import React, {useState} from "react";
import {Modal, Button, Form, Input, message} from "antd";
import {MailOutlined} from "@ant-design/icons";
import {API_INSTANCE} from "../axios";

const UseRequireCredentialsModal = () => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const toggleRequireCredentialsVisible = () =>
        setVisible((visible) => !visible);

    const require = (data) => {
        setLoading(true)

        API_INSTANCE.put(`/changePassword/rest_password`, {
            email: data.email
        })
            .then(() => {
                message.success("You will receive an email with your new password").then();
            })
            .catch((error) => {
                let res = error.response;
                res.status === 304 && message.warning("Password did not updated");
                res.status === 400 && message.error("Please check your email or data inserted");
            })
            .finally(() => {
                setLoading(false)
                setVisible(false)
            });
    };

    return {
        RequireCredentialModal: () => (
            <Modal
                open={visible}
                centered
                title="Reset Password"
                onCancel={toggleRequireCredentialsVisible}
                footer={null}
                zIndex={100000}
            >
                <Form onFinish={require} layout="inline">
                    <Form.Item
                        name="email"
                        rules={[{required: true, message: "Email required."}]}
                        style={{flex: 99}}
                    >
                        <Input
                            size={"large"}
                            type="email"
                            placeholder="Email"
                            prefix={<MailOutlined/>}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button size={"large"} htmlType="submit" type="primary" loading={loading}>
                            Require
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <span style={{color: "#666666"}}>*you'll receive an email with a temporary password</span>
                    </Form.Item>
                </Form>
            </Modal>
        ),
        toggleRequireCredentialsVisible,
    };
};

export default UseRequireCredentialsModal;
