let user = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : "";

let admin =
  localStorage.getItem("user") &&
  JSON.parse(localStorage.getItem("user")).is_tenant;

let token = localStorage.getItem("JWT") ? localStorage.getItem("JWT") : "";

let login_at = localStorage.getItem("login_at")
  ? localStorage.getItem("login_at")
  : "";

export const initialState = {
  user: "" || user,
  token: "" || token,
  isAdmin: false || admin,
  loading: false,
  errorMessage: null,
  login_at: "" || login_at,
};

export const AuthReducer = (initialState, action) => {
  switch (action.type) {
    case "REQUEST_LOGIN":
      return {
        ...initialState,
        loading: true,
      };
    case "LOGIN_SUCCESS":
      return {
        ...initialState,
        user: action.payload.data,
        isAdmin: false || action.payload.data.is_tenant === true,
        token: action.payload.JWT,
        loading: false,
        login_at: action.payload.login_at
      };
    case "LOGOUT":
      return {
        ...initialState,
        user: "",
        token: "",
        isAdmin: false,
      };
    case "LOGIN_ERROR":
      return {
        ...initialState,
        loading: false,
        isAdmin: false,
        errorMessage: action.error,
      };
    case "UPDATE_MUST_CHANGE_PASSWORD":
      localStorage.setItem("user", JSON.stringify({...user, must_change_password: action.payload}))
      return {
        ...initialState,
        user:{
          ...user,
          must_change_password: action.payload
        }
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
