import {useState} from "react";
import {Form, Input, Button, message} from "antd";
import {ErrorHandler} from "../Shared/ErrorHandler";
import {PUT_REQUEST} from "../Shared/Requests";
import {resetValues} from "../Shared/CommonFunctions";
import {Loader} from "../Shared/Layout";
import useWindowDimensions from "../../Hooks/useWindowDimensions";
import {logout, useAuthDispatch} from "../Shared/AuthContext";


const ChangePassword = ({uid}) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useAuthDispatch();
    const [input, setInput] = useState({
        old_password: "",
        new_password: "",
        repeat_password: "",
    });
    const {width} = useWindowDimensions();

    const handleInput = (e) => {
        setInput({...input, [e.target.name]: e.target.value});
    };

    const submitNewPassword = () => {
        if (input.new_password === input.repeat_password) {
            setLoading(true);
            let obj = {
                id: uid,
                old: input.old_password,
                new: input.new_password,
            };

            try {
                PUT_REQUEST(`/changePassword/${uid}`, obj)
                    .then(() => {
                        logout(dispatch).then()
                        message.success("Password successfully updated").then();
                        resetValues(input, setInput);
                    })
                    .catch((error) => {
                        let res = error.response;
                        ErrorHandler(res)
                    })
                    .finally(() => setLoading(false));
            } catch (error) {
                ErrorHandler(error.response);
            }
        } else {
            message.warning("password did not match! Please check your passwords").then();
        }
    };

    return (
        <div
            style={{display: "flex", flexDirection: "column", alignItems: "center"}}
        >
            {loading && <Loader/>}
            <Form
                style={{width: width < 1024 ? "100%" : "50%"}}
                name="changePassword"
                labelCol={{span: 5}}
                wrapperCol={{span: 20}}
                initialValues={{remember: true}}
                onFinish={submitNewPassword}
            >
                <Form.Item
                    label="Old Password"
                    rules={[
                        {required: true, message: "Please input your old password!"},
                    ]}
                >
                    <Input.Password
                        size={"large"}
                        value={input.old_password}
                        onChange={handleInput}
                        name="old_password"
                    />
                </Form.Item>

                <Form.Item
                    label="New Password"
                    rules={[
                        {required: true, message: "Please input your new password!"},
                    ]}
                >
                    <Input.Password
                        size={"large"}
                        value={input.new_password}
                        onChange={handleInput}
                        name="new_password"
                    />
                </Form.Item>

                <Form.Item
                    label="Repeat Password"
                    rules={[{required: true, message: "Please confirm your password!"}]}
                >
                    <Input.Password
                        size={"large"}
                        value={input.repeat_password}
                        onChange={handleInput}
                        name="repeat_password"
                    />
                </Form.Item>

                <Form.Item style={{float: "right"}}>
                    <Button size={"large"} type="primary" htmlType="submit">
                        Change Password
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default ChangePassword;
