import {Typography, Divider, Tabs} from "antd";
import ChangePassword from "./ChangePassword";
import {Layout} from "../Shared/Layout";
import {useAuthState} from "../Shared/AuthContext";
import {useHistory, useParams} from "react-router";

const Profile = () => {
    const {user}: any = useAuthState();
    const history = useHistory();
    const {key}: any = useParams();
    const items = [{label: 'Change Password', key: '2', children: <ChangePassword uid={user.key}/>}]

    return (
        <Layout>
            <Typography.Title level={2}>Profile</Typography.Title>

            {user && (
                <>
                    <Divider/>
                    <Tabs
                        className="profile-card-tabs"
                        type="line"
                        defaultActiveKey={key}
                        onChange={(key) => history.push(`/profile/${key}`)}
                        items={items}
                    />
                </>
            )}
        </Layout>
    );
};

export default Profile;
