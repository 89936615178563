import { AxiosResponse } from "axios"
import { API_INSTANCE } from "../../axios"

/**
 * API actions
 */
export const POST = "POST";
export const GET = "GET";
export const UPDATE = "UPDATE";
export const DELETE = "DELETE";
export const PUT = "PUT";

/**
 * API end points
 */
export const ITEMS_PATH = "/products";
export const ORDERS_PATH = "/orders";
export const CLIENTS_PATH = "/clients";
export const USERS_PATH = "/users";
export const MEDIA_PATH = "/files";

/**
 * @returns http response
 * Base URL https://g0jrxuxzuh.execute-api.eu-west-1.amazonaws.com/v1
 * @param path
 * @param body
 */
export async function POST_REQUEST(path, body) {
    let response: AxiosResponse = await API_INSTANCE.post(path, body, {
        headers: {
            'Authorization': "Bearer " + window.localStorage.getItem('JWT')
        }
    })
    return response.data.data || []
}

/**
 * API call function for get data from database
 * Base URL https://g0jrxuxzuh.execute-api.eu-west-1.amazonaws.com/v1
 * @param path end point of the request
 * @returns data from the response
 */
export async function GET_REQUEST<T>(path) {
    let response: AxiosResponse = await API_INSTANCE.get(path, {
        headers: {
            'Authorization': "Bearer " + window.localStorage.getItem('JWT')
        }
    })

    return response.data.data || []
}

export async function DELETE_REQUEST<T>(path) {
    let response: AxiosResponse = await API_INSTANCE.delete(path, {
        headers: {
            'Authorization': "Bearer " + window.localStorage.getItem('JWT')
        }
    })

    return response.data.data || []
}

export async function PUT_REQUEST<T>(path, data) {
    let response: AxiosResponse = await API_INSTANCE.put(path, data,{
        headers: {
            'Authorization': "Bearer " + window.localStorage.getItem('JWT')
        }
    })

    return response.data.data || []
}

/**
 * API POST, Update, DELETE request function
 * @param path end point of the request
 * @param action what to do (POST, UPDATE or DELETE)
 * @param payload the data to save
 * @param key if UPDATE or DELETE, item's key/id
 * @param foreign_keys if exist reference to other tables
 * @returns data from the response
 */
export async function API_REQUEST(path, action, payload = null, key = null, foreign_keys = null) {
    try {
        let response: AxiosResponse = await API_INSTANCE.post(path, {
            action,
            key,
            foreign_keys,
            payload
        }, {
            headers: {
                'Authorization': "Bearer " + window.localStorage.getItem('JWT')
            }
        })
        
        return response.data
    } catch (error) {
        throw error
    }

}
