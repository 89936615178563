import {memo, useEffect, useMemo, useState} from "react";
import {PageHeader, List, Typography, Button, Input, Tooltip, Space, Segmented, Badge, Select} from "antd";
import {Layout} from "../Shared/Layout";
import {orders} from "../Shared/Interface";
import {
    useAuthState,
} from "../Shared/AuthContext";
import {Card, Empty, Skeleton} from "antd";
import {GET_REQUEST, ORDERS_PATH} from "../Shared/Requests";
import {ErrorHandler} from "../Shared/ErrorHandler";
import {useHistory} from "react-router";
import {FilterOutlined, SortAscendingOutlined, SortDescendingOutlined} from "@ant-design/icons";
import login from "../Login";
import * as moment from "moment";

/**
 * Main component of customer interface,
 * starts with displaying Orderss
 * @returns {React.FC}
 */
const Orders = () => {
    const {user}: any = useAuthState();
    const h = useHistory();
    const [orders, setOrders] = useState<orders[]>([]);
    const [loading, setLoading] = useState(false);
    const [asc, setAsc] = useState(1);
    const [filter, setFilter] = useState("");
    const [sortBy, setSortBy] = useState("name");

    useEffect(() => {
        if (h.location.state) {
            // @ts-ignore
            setFilter(h.location.state.filter)
            // @ts-ignore
            setAsc(h.location.state.asc)
        }
        getOrders();
    }, []);

    const getOrders = () => {
        try {
            setLoading(true)
            GET_REQUEST<orders[]>(
                `${ORDERS_PATH}/${encodeURIComponent(user.key)}`
            )
                .then(data => {
                    setOrders(data)
                })
                .catch((error) => ErrorHandler(error.response))
                .finally(() => setLoading(false))
        } catch (error) {
            ErrorHandler(error.response);
            setLoading(false)
        }
    };

    function groupBy(list, keyGetter) {
        const map = new Map();

        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });

        return map;
    }

    const ds = useMemo(() => {
        let data = orders.filter(d => d.name.toLowerCase().includes(filter.toLowerCase())
            || d.external_key.toLowerCase().includes(filter.toLowerCase())
            || d["c.name"].toLowerCase().includes(filter.toLowerCase()))

        if (asc) {
            return data.sort((a, b) => {
                let x: Array<string> = a["external_key"].split(" ")
                let y: Array<string> = b["external_key"].split(" ")
                let momentX = moment(x[x.length - 1], "DD-MM-YYYY");
                let momentY = moment(y[y.length - 1], "DD-MM-YYYY");

                // @ts-ignore
                return a.name.localeCompare(b.name) || a.external_key.localeCompare(b.external_key) || (new Date(momentX.isValid() ? momentX.toString() : "1970-01-01") - new Date(momentY.isValid() ? momentY.toString() : "1970-01-01"))
            })

            // if (sortBy !== "date")
            //     return data.sort((a, b) =>
            //         (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) ? -1 : (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) ? 1 : 0)
            // else
            //     return data.sort((a, b) => {
            //         let x: Array<string> = a["external_key"].split(" ")
            //         let y: Array<string> = b["external_key"].split(" ")
            //         let momentX = moment(x[x.length - 1], "DD-MM-YYYY");
            //         let momentY = moment(y[y.length - 1], "DD-MM-YYYY");
            //         console.log(momentX)
            //         // @ts-ignore
            //         return (new Date(momentX.isValid() ? momentX.toString() : "1970-01-01") - new Date(momentY.isValid() ? momentY.toString() : "1970-01-01"))
            //     })
        } else {
            return data.sort((a, b) => {
                let x: Array<string> = a["external_key"].split(" ")
                let y: Array<string> = b["external_key"].split(" ")
                let momentX = moment(x[x.length - 1], "DD-MM-YYYY");
                let momentY = moment(y[y.length - 1], "DD-MM-YYYY");

                // @ts-ignore
                return b.name.localeCompare(a.name) || b.external_key.localeCompare(a.external_key) || (new Date(momentY.isValid() ? momentY.toString() : "1970-01-01") - new Date(momentX.isValid() ? momentX.toString() : "1970-01-01"))
            })

            // if (sortBy !== "date")
            //     return data.sort((a, b) =>
            //         (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) ? -1 : (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) ? 1 : 0)
            // else
            //     return data.sort((a, b) => {
            //         let x: Array<string> = a["external_key"].split(" ")
            //         let y: Array<string> = b["external_key"].split(" ")
            //         let momentX = moment(x[x.length - 1], "DD-MM-YYYY");
            //         let momentY = moment(y[y.length - 1], "DD-MM-YYYY");
            //
            //         // @ts-ignore
            //         return (new Date(momentY.isValid() ? momentY.toString() : "1970-01-01") - new Date(momentX.isValid() ? momentX.toString() : "1970-01-01"))
            //     })
        }
    }, [orders, asc, filter, sortBy]);

    const handleFilter = ({target}) => {
        setFilter(target.value)
    }

    /**
     * change component between Order, Item and Media
     * @param focus to understand on which component selected
     * @param title
     */
    const changeFocus = (focus, title) => {
        title ?
            h.push(
                `/orders/${encodeURIComponent(focus)}/${encodeURIComponent(title)}`, {
                    filter: filter,
                    asc: asc
                }
            ) : h.push("/404")
    };

    return (
        <Layout>
            <div id="customer" style={{height: "100%"}}>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    padding: "5px 0px 30px 0"
                }}>
                    <PageHeader
                        title={`${ds.length !== orders.length ? `${ds.length} out of ${orders.length} orders displayed` : `Total of ${orders.length} orders displayed`}`}
                        subTitle={"Click an order to see the items"}
                        onBack={null}
                        style={{flex: 5, paddingBottom: 0, paddingTop: 0}}
                    />
                    <div style={{
                        display: "flex", flex: 3, gap: '10px',
                        // flexDirection: "column"
                    }}>

                        <Input style={{flex: 1}} onInput={handleFilter} value={filter}
                               placeholder={"Enter keyword to filter..."}
                               prefix={<FilterOutlined/>}/>

                        <Segmented
                            value={asc}
                            options={[
                                {
                                    value: 1,
                                    icon: <SortAscendingOutlined style={{fontSize: "1.1rem"}}/>,
                                    title: "Ascending"
                                },
                                {
                                    value: 0,
                                    icon: <SortDescendingOutlined style={{fontSize: "1.1rem"}}/>,
                                    title: "Descending"
                                }
                            ]}
                            onChange={(a: any) => setAsc(a)}
                        />

                        {/*<div style={{flex: 1, display: "flex"}}>*/}
                        {/*<Select*/}
                        {/*    defaultValue={sortBy}*/}
                        {/*    style={{width: 120, flex: 1}}*/}
                        {/*    onChange={e => setSortBy(e)}*/}
                        {/*    options={[*/}
                        {/*        {*/}
                        {/*            value: 'name',*/}
                        {/*            label: 'Sort by Name',*/}
                        {/*        },*/}
                        {/*        {*/}
                        {/*            value: 'external_key',*/}
                        {/*            label: 'Sort by Key',*/}
                        {/*        },*/}
                        {/*        {*/}
                        {/*            value: 'date',*/}
                        {/*            label: 'Sort by Date',*/}
                        {/*        },*/}
                        {/*    ]}*/}
                        {/*/>*/}

                        {/*    <Segmented options={[*/}
                        {/*        {*/}
                        {/*            value: 1,*/}
                        {/*            icon: <SortAscendingOutlined style={{fontSize: "1.1rem"}}/>,*/}
                        {/*            title: "Ascending"*/}
                        {/*        },*/}
                        {/*        {*/}
                        {/*            value: 0,*/}
                        {/*            icon: <SortDescendingOutlined style={{fontSize: "1.1rem"}}/>,*/}
                        {/*            title: "Descending"*/}
                        {/*        }*/}
                        {/*    ]}*/}
                        {/*               onChange={(a: any) => setAsc(a)}*/}
                        {/*    />*/}
                        {/*</div>*/}

                    </div>
                </div>

                <List size="large">
                    {user && <OrderCard orders={ds} changeFocus={changeFocus} user={user} loading={loading}/>}
                </List>
            </div>
        </Layout>
    );
};

export default Orders;

type OrderCardProps = {
    changeFocus: Function;
    user: any;
    orders: any;
    loading: boolean;
};
const OrderCard = ({changeFocus, user, orders, loading}: OrderCardProps) => {
    return (
        <>
            <Skeleton loading={loading} active key={user.id}>
                {orders.length > 0 ? (
                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(auto-fit, minmax(350px,1fr))",
                        gap: '20px',
                        paddingBottom: "2vh"
                    }}>
                        {orders.map(order =>
                            <Card
                                key={order.key}
                                // title={order.external_key}
                                hoverable={true}
                                bordered={false}
                                className={"customer-order-detail-card"}
                                style={{padding: ".75em"}}
                                onClick={changeFocus.bind(this, order.key, order.external_key)}
                            >
                                <Typography.Title level={5} style={{
                                    fontSize: "18px",
                                    marginBottom: 0
                                }}>{order.external_key}</Typography.Title>
                                <Typography.Title level={5} style={{color: "gray", marginTop: "5px"}}>
                                    {order.name || "-"}
                                </Typography.Title>
                                {
                                    // user.is_tenant &&
                                    <Typography.Title level={5}
                                                      style={{color: "gray", fontSize: "13px", marginBottom: 0}}>
                                        {order["c.name"]}
                                    </Typography.Title>
                                }
                            </Card>
                        )}
                        {/*<Row*/}
                        {/*    gutter={[24, 12]}*/}
                        {/*    style={{flexDirection: width < 700 ? "column" : "row"}}*/}
                        {/*>*/}
                        {/*    {orders.map((order) => (*/}
                        {/*        <Col*/}
                        {/*            xs={24}*/}
                        {/*            sm={24}*/}
                        {/*            md={12}*/}
                        {/*            lg={6}*/}
                        {/*            xl={6}*/}
                        {/*            xxl={6}*/}
                        {/*            key={order.key}*/}
                        {/*            style={{marginBottom: "10px"}}*/}
                        {/*            onClick={changeFocus.bind(this, order.key, order.external_key)}*/}
                        {/*        >*/}
                        {/*            <Card*/}
                        {/*                // title={order.external_key}*/}
                        {/*                bordered={false}*/}
                        {/*                className={"customer-order-detail-card"}*/}
                        {/*                style={{padding: ".75em"}}*/}
                        {/*            >*/}
                        {/*                <Typography.Title level={5} style={{fontSize: "18px", marginBottom: 0}}>{order.external_key}</Typography.Title>*/}
                        {/*                <Typography.Title level={5} style={{color: "gray", marginTop: "5px"}}>*/}
                        {/*                    {order.name || "-"}*/}
                        {/*                </Typography.Title>*/}
                        {/*                {*/}
                        {/*                    user.is_tenant &&*/}
                        {/*                    <Typography.Title level={5} style={{color: "gray", fontSize: "13px", marginBottom: 0}}>*/}
                        {/*                        {order["c.name"]}*/}
                        {/*                    </Typography.Title>*/}
                        {/*                }*/}
                        {/*            </Card>*/}
                        {/*        </Col>*/}
                        {/*    ))}*/}
                        {/*</Row>*/}
                    </div>
                ) : (
                    <Empty
                        description="No orders found"
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                )}
            </Skeleton>
        </>
    );
};
