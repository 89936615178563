// @ts-ignore
import React, {useEffect, useState} from "react";
import {Badge, Card, Col, Empty, message, PageHeader, Row, Skeleton, Typography} from "antd";
import {item, orders} from "../Shared/Interface";
import {ErrorHandler} from "../Shared/ErrorHandler";
import {GET_REQUEST, ITEMS_PATH} from "../Shared/Requests";
import {useHistory, useParams} from "react-router-dom";
import {Layout} from "../Shared/Layout";
import {ArrowLeftOutlined, ToolFilled} from "@ant-design/icons";
import useWindowDimensions from "../../Hooks/useWindowDimensions";

/**
 * Display all the items of a selected order
 * @returns {React.FC}
 */
const ItemList = () => {
    const {order_id, order_name}: any = useParams();
    const [items, setItems] = useState<item[]>([]);
    const [loading, setLoading] = useState(false);
    const h = useHistory();

    useEffect(() => {
        getItems().then();
    }, []);

    /**
     * Get all items from backend,
     * and set state
     */
    const getItems = async () => {
        setLoading(true);
        try {
            GET_REQUEST(`${ITEMS_PATH}/${order_id}`)
                .then(setItems)
                .catch((error) => console.log(error))
                .finally(() => setLoading(false));
        } catch (error) {
            ErrorHandler(error.response);
        }
    };

    /**
     * change component between Order, Item and Media
     * @param focus to understand on which component selected
     * @param title
     * @param itn
     */
    const changeFocus = (focus, title, itn) => {
        title ?
            h.push(
                `/products/${encodeURIComponent(focus)}/${encodeURIComponent(title)}?or=${encodeURIComponent(order_id)}`, h.location.state
            ) : h.push("/404")
    };

    return (
        <Layout>
            <PageHeader
                title={
                    <div>
                        <ArrowLeftOutlined
                            style={{fontSize: "1rem", marginRight: "1rem"}}
                            onClick={() => h.location.state["search"] ? h.goBack() : h.push("/orders", h.location.state)}
                        />
                        <span>Order: {decodeURIComponent(order_name)}</span>
                    </div>
                }
                subTitle={"Click an item to see the media list"}
            />

            {/*<Badge.Ribbon text={"Service"}>*/}
            {(
                <Skeleton loading={loading} active>
                    {items.length > 0 ? (
                        <div style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(auto-fit, minmax(350px,1fr))",
                            gap: '20px'
                        }}>
                            {items.map((i: any) => (
                                <Badge.Ribbon key={i.key} style={{display: `${i.is_service === 1 ? 'block' : 'none'}`}} text={"Service"}>
                                    <Card
                                        // title={<div>
                                        //     <span style={{margin: "0 5px 0 0"}}>{i.external_key}</span>
                                        //     {i.is_service === 1 && <ToolFilled/>}
                                        // </div>}
                                        hoverable={true}
                                        bordered={false}
                                        style={{padding: ".75em"}}
                                        className={"customer-order-detail-card"}
                                        onClick={changeFocus.bind(this, i.key, i.external_key)}
                                    >
                                        <Typography.Title level={5} style={{
                                            fontSize: "18px",
                                            marginBottom: 0
                                        }}>{i.external_key}</Typography.Title>
                                        <Typography.Title level={5} style={{color: "gray", marginTop: "5px"}}>
                                            {i.name}
                                        </Typography.Title>
                                    </Card>
                                </Badge.Ribbon>
                            ))}
                        </div>
                    ) : (
                        <Empty
                            description="No item found"
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                        />
                    )}
                </Skeleton>
            )}
        </Layout>
    );
};

export default ItemList;
