import {
    Input,
    Button,
    Table,
    Form,
    Typography,
    message,
    Modal,
    Tooltip,
    PageHeader,
} from "antd";
import {useEffect, useState} from "react";
import {customer, user} from "../Shared/Interface";
import {
    ArrowLeftOutlined, ExclamationCircleOutlined,
} from "@ant-design/icons";
import {ErrorHandler} from "../Shared/ErrorHandler";
import {
    ascend,
    descend,
    resetValues,
} from "../Shared/CommonFunctions";
import {
    DELETE_REQUEST,
    GET_REQUEST,
    POST_REQUEST,
    USERS_PATH,
} from "../Shared/Requests";
import useWindowDimensions from "./../../Hooks/useWindowDimensions";
import {useParams} from "react-router";
import {Layout} from "../Shared/Layout";
import {useHistory} from "react-router-dom";


/**
 * Child component under Admin/index.tsx
 * @returns {React.FC} Customers table
 */
const Users = () => {
    const {client_id, client_name}: any = useParams();
    const [dataSource, setDataSource] = useState([]);
    const [input, setInput] = useState({
        username: "",
        orders: [],
    });
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const {width} = useWindowDimensions();
    const [loading, setLoading] = useState(false);
    const h = useHistory();

    /**
     * This useEffect get Users
     */
    useEffect(() => {
        try {
            getUsers().then();
        } catch (error) {
            ErrorHandler(error.response);
        }
        return () => {
            setDataSource([]);
        };
    }, [client_id]);

    /**
     * In this async function get the customers and set the state
     */
    const getUsers = async () => {
        setLoading(true);

        GET_REQUEST<customer[]>(`${USERS_PATH}/${client_id}`)
            .then(data => {
                setDataSource(data)
            })
            .catch((err) => ErrorHandler(err.response))
            .finally(() => setLoading(false));
    };

    /**
     * Set the state of inputs for a new order
     * @param e the event of onInput.
     */
    const handleInput = (e) => {
        setInput({...input, [e.target.name]: e.target.value});
    }

    /**
     * set the state of isModalVisible to true
     */
    const showModal = () => setIsModalVisible(true);

    /**
     * Set the isModalVisible to false and reset the state of input
     */
    const handleCancel = () => {
        delete input.orders;
        resetValues(input, setInput);
        setIsModalVisible(false);
    };

    /**
     * Ask the admin to confirm to delete before actual deletion
     * @param id get the id of the user to delete
     */
    const confirm = (id) => {
        Modal.confirm({
            title: "Confirm",
            icon: <ExclamationCircleOutlined/>,
            content: "Are you sure, you want to delete this user?",
            okText: "Yes, Delete",
            cancelText: "No",
            onOk: () => submitDelete(id),
        });
    };

    /**
     * Ask the admin to confirm to delete before actual deletion
     * @param id get the id of the user to delete
     */
    const submitDelete = (id) => {
        DELETE_REQUEST(`${USERS_PATH}/${id}`)
            .then(() => {
                setDataSource(dataSource.filter((data) => data.key !== id));
                message.success("User successfully deleted").then();
            })
            .catch((error) => ErrorHandler(error.response))
            .finally(() => setButtonLoading(false));
    };

    /**
     * Submit the new user
     * @param close parameter which indicate is admin adding one or more user together
     */
    const handleNewUser = (close = true) => {
        const emails = input.username.split(/\r?\n/)
            .map(e => e.trim())
            .filter(element => element)

        for (const i in emails) {
            if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(emails[i])) {
                setButtonLoading(true);

                POST_REQUEST(USERS_PATH, {
                    username: emails[i],
                    client_key: client_id,
                    must_change_password: true
                })
                    .then((data) => {
                        close && setIsModalVisible(false);

                        setDataSource([...dataSource, data]);
                        setInput({
                            orders: [],
                            username: ""
                        })
                        message.success("New user will receive an email with user credential").then();
                    })
                    .catch(console.error)
                    .finally(() => setButtonLoading(false))

            } else {
                message.error(`Invalid email - ${emails[i]}!`).then()
            }
        }
    };

    /**
     * Columns of the users table
     */
    const columns: any = [
        {
            title: "Name",
            dataIndex: "username",
            width: "85%",
            showSorterTooltip: false,
            defaultSortOrder: localStorage.getItem("us") || "ascend",
            sorter: (a, b, c) => {
                localStorage.setItem("us", c)
                return c === "ascend" ? ascend(a, b, "username") : descend(a, b, "username")
            },
        },
        {
            title: "Action",
            width: "15%",
            render: (data: user) => (
                <div style={{display: "flex", justifyContent: "center"}}>
                    <Tooltip
                        placement="top"
                        title={"Delete User"}
                    >
                        <Button
                            type={"link"}
                            onClick={() => confirm(data.key)}
                        >
                            <p style={{color: "red"}}>Delete</p>
                        </Button>
                    </Tooltip>
                </div>
            ),
        },
    ];


    return (
        <Layout>
            <div id="user"> {/*style={{padding: "1vh"}}*/}
                <div className="admin-title">
                    <PageHeader
                        style={{padding: 0}}
                        title={
                            <div
                                style={{float: "left", marginBottom: 0, display: "flex", alignItems: "center"}}
                            >
                                <ArrowLeftOutlined
                                    style={{fontSize: "1rem", marginRight: "1rem"}}
                                    onClick={() => h.goBack()}
                                />
                                <Typography.Title
                                    level={width < 1024 ? 5 : 3}
                                    style={{margin: 0}}
                                    ellipsis={client_name.length > 30 ? {
                                        suffix: "...",
                                    }: false}
                                >
                                    {client_name.length >= 30 ? client_name.slice(0,(30)).trim() : client_name}
                                </Typography.Title>
                                <Typography.Title
                                    level={width < 1024 ? 4 : 3}
                                    style={{margin: 0}}
                                >
                                    's users
                                </Typography.Title>
                            </div>
                        }
                    />
                    <Button className="fixed-button" size={"large"} onClick={showModal}>
                        Add new user/s
                    </Button>
                </div>

                <Table size={"middle"} dataSource={dataSource} columns={columns} loading={loading}
                       style={{marginTop: "25px"}}/>

                {/* Insert Modal */}
                <InputModal
                    title={"Add user/s"}
                    loading={buttonLoading}
                    isModalVisible={isModalVisible}
                    handleCancel={handleCancel}
                    handleNewUser={handleNewUser}
                    input={input}
                    handleInput={handleInput}
                />
            </div>
        </Layout>
    );
};

export default Users;

type InputModalProps = {
    title: any;
    isModalVisible: any;
    handleCancel: any;
    handleNewUser: any;
    input: any;
    handleInput: any;
    loading: any;
};
export const InputModal = ({
                               title,
                               isModalVisible,
                               handleCancel,
                               handleNewUser,
                               input,
                               handleInput,
                               loading,
                           }: InputModalProps) => {

    return (<Modal
            centered
            title={
                <Typography.Title level={3} style={{margin: "0"}}>
                    {title}
                </Typography.Title>
            }
            width={720}
            open={isModalVisible}
            onCancel={handleCancel}
            maskClosable={false}
            onOk={handleNewUser}
            okText={"Add and close"}
            okButtonProps={{loading}}
        >
            <p><b>To add more users in one instant you can insert multiple emails divided by new line</b></p>
            <Form id="client" labelCol={{span: 5}} wrapperCol={{span: 20}}>
                <>
                    <Form.Item label="E-mail">
                        <Input.TextArea
                            name="username"
                            rows={5}
                            value={input.username}
                            onChange={handleInput}
                            required
                            disabled={title === "Edit user"}
                        />
                    </Form.Item>
                </>
            </Form>
        </Modal>
    );
};
