import {AxiosResponse} from "axios";
import {message} from 'antd';

export const ErrorHandler = (response: AxiosResponse, msg = "") => {
    let status = response && response.status || "";

    switch (status) {
        case 204:
            message.info("No content!").then()
            break;
        case 304:
            message.warning("No data has been modified").then()
            break;
        case 400:
            message.error(response.data.message).then()
            break;
        case 401:
            message.error(response.data.message).then()
            break;
        case 403:
            message.error(response.data.message).then()
            break;
        case 404:
            response.data.message ? message.error(response.data.message).then() : message.error("Not Found!")
            break;
        case 500:
            let x: string = response.data.message ? response.data.error_message
                .split(",")[0]
                .replace("(", "") : "";

            if (x === '1451')
                message.error(`You cannot delete this ${msg}! Because it's not empty`).then()
            else
                message.error(response.data.message).then()
            break;
        default:
            message.warning("Something went wrong!").then()
            break;
    }

}
