import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom";
import Orders from "./Customer";
import Login from "./Login";
import Profile from "./Profile";
import Admin from "./Admin";
import {useAuthState} from "./Shared/AuthContext";
import ItemList from "./Customer/ItemList";
import Users from "./Admin/Users";
import Search from "./Search";
import Results from "./Search/Results";
import Media from "./Customer/Media";
import ChangePassword from "./Profile/ChangePassword";
import NotFound from "./Shared/NotFound";
import ManageClients from "./Admin/ManageClients";

/**
 * The component where handle privileges and paths
 * @returns React.FC
 */
const Router = () => {
    const pathname = window.location.pathname;
    const {isAdmin, user}: any = useAuthState();

    return (
        <BrowserRouter>
            <Switch>
                {user !== "" ? (
                    <>
                        {isAdmin ? (
                            <>
                                <Route exact path="/search" component={Search}/>
                                <Route exact path="/search/:query" component={Results}/>
                                <Route exact path="/profile/:key" component={Profile}/>
                                <Route exact path="/orders" component={Orders}/>
                                <Route
                                    exact
                                    path="/orders/:order_id/:order_name"
                                    component={ItemList}
                                />
                                <Route exact path="/products/:sn/:itn" component={Media}/>
                                <Route exact path="/admin" component={Admin}/>
                                <Route exact path="/admin/manage_client/:client_id/:client_name" component={ManageClients}/>
                                <Route
                                    exact
                                    path="/:key/client/:client_id/:client_name"
                                    component={Users}
                                />
                                <Route exact path="/404" component={NotFound}/>

                                {!["search", "profile", "orders", "products", "admin"].includes(pathname.split("/")[1]) && <Redirect to={"/404"}/>}

                                {pathname === "/" && <Redirect from={"/"} to="/orders"/>}
                            </>
                        ) : user.must_change_password ? (
                            <div
                                style={{
                                    height: "100vh",
                                    width: "100vw",
                                    textAlign: "center",
                                    padding: "5rem",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        height: "100%",
                                    }}
                                >
                                    <h1>
                                        For security reasons your admin suggesting you to change
                                        your password!
                                    </h1>
                                    <ChangePassword uid={user.key}/>
                                </div>
                            </div>
                        ) : (
                            <>
                                <Route exact path="/search" component={Search}/>
                                <Route exact path="/search/:query" component={Results}/>
                                <Route exact path="/profile/:key" component={Profile}/>
                                <Route exact path="/orders" component={Orders}/>
                                <Route
                                    exact
                                    path="/orders/:order_id/:order_name"
                                    component={ItemList}
                                />
                                <Route exact path="/products/:sn/:itn" component={Media}/>

                                <Route exact path="/404" component={NotFound}/>

                                {!["search", "profile", "orders", "products"].includes(pathname.split("/")[1]) && <Redirect to={"/404"}/>}

                                {pathname === "/" && <Redirect to="/orders"/>}
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <div
                            style={{
                                position: "absolute",
                                width: "100vw",
                                height: "100vh",
                                zIndex: 9999,
                            }}
                        >
                            <Login/>
                        </div>
                    </>
                )}
            </Switch>
        </BrowserRouter>
    );
};

export default Router;
