import {useHistory, useParams} from "react-router-dom";
import {Layout, Loader} from "../Shared/Layout";
import {Badge, Button, Card, Checkbox, Col, Empty, Form, Input, Row, Typography} from "antd";
// @ts-ignore
import logo from "../../Resources/new_logo.svg"
import {GET_REQUEST} from "../Shared/Requests";
import {useEffect, useMemo, useState} from "react";
import {Link} from "react-router-dom";
import useWindowDimensions from "../../Hooks/useWindowDimensions";
import {ErrorHandler} from "../Shared/ErrorHandler";


const Results = () => {
    const params: any = useParams();
    const [f, setF] = useState([]);
    const [original, setOriginal] = useState([]);
    const {width} = useWindowDimensions();
    const history = useHistory()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        handleSearch().then()
    }, []);

    const results = useMemo(() => original.filter((item) => f.includes(item.type.toLowerCase())), [original, f]);

    const handleSearch = async (values = {search: "", filter: ["orders", "products"]}) => {
        try {
            setLoading(true)
            values.search && history.replace(`/search/${encodeURIComponent(values.search)}`);

            let data = await GET_REQUEST(`/search?q=${encodeURIComponent(values.search || params.query)}`)

            setOriginal(data)
            setF(values.filter)
            setLoading(false)
        } catch (e) {
            setLoading(false)
        }
    }

    return (
        <Layout>
            <div className={"results"}>
                <Form
                    style={{width: "100%"}}
                    onFinish={handleSearch}>
                    <Row gutter={24} style={{alignItems: "center", justifyContent: "center"}}>
                        {width > 1023 && <Col span={2}>
                            <Form.Item>
                                <Link to={"/search"}><img src={logo} alt="logo" style={{width: "100%"}}/></Link>
                            </Form.Item>
                        </Col>}
                        <Col span={width < 1024 ? 12 : 8}>
                            <Form.Item name={"search"} rules={[{required: true, message: "Insert a keyword"}]}
                                       initialValue={params.query}>
                                <Input type={"search"} placeholder={"Search"}/>
                            </Form.Item>
                        </Col>
                        <Col span={width < 1024 ? 8 : 2}>
                            <Form.Item>
                                <Button type={"primary"} htmlType={"submit"} style={{width: "100%"}}>Search</Button>
                            </Form.Item>
                        </Col>
                        <Col span={width < 1024 ? 12 : 4}>
                            <Form.Item name={"filter"} style={{width: "100%"}} initialValue={["orders", "products"]}>
                                <Checkbox.Group style={{display: "flex"}}
                                                options={[{label: "Orders", value: "orders"}, {
                                                    label: "Products",
                                                    value: "products"
                                                }]} onChange={(e) => setF(e)}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>

            <div className={"results-list"}>
                <p className={"results-list-item-detail-link"}>There are {results.length} results for keyword
                    "{params.query}"</p>
                <div style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(auto-fit, minmax(350px,1fr))",
                    gap: '20px'
                }}>
                    {
                        loading != true ?
                            results.length !== 0
                                ? (
                                    results.map(result => {
                                        return (
                                            <Link
                                                key={result.key}
                                                to={{
                                                    pathname: `/${result.type}/${encodeURIComponent(result.key)}/${encodeURIComponent(result.external_key)}${result.type === 'products' ? `?or=${encodeURIComponent(result.order_key)}` : ""}`,
                                                    state: {
                                                        search: true
                                                    }
                                                }}>
                                                <Badge.Ribbon
                                                    key={result.key}
                                                    text={result.type === "orders" ? "Order" : "Product"}
                                                    style={{background: result.type === "orders" ? "" : "#5388e6"}}
                                                >
                                                    <Card
                                                        hoverable={true}
                                                        bordered={true}
                                                        style={{padding: ".75em"}}
                                                    >
                                                        <Typography.Title level={5} style={{
                                                            fontSize: "18px",
                                                            marginBottom: 0
                                                        }}>{result.name || result.external_key}</Typography.Title>
                                                        <Typography.Title level={5}
                                                                          style={{color: "gray", marginTop: "5px"}}>
                                                            {result.external_key}
                                                        </Typography.Title>
                                                    </Card>
                                                </Badge.Ribbon>
                                            </Link>
                                        )
                                    })
                                )
                                : (
                                    <div style={{position: "absolute", left: 0, width: "100%", textAlign: "center"}}>
                                        <Empty/>
                                    </div>
                                )
                            : <Loader/>
                    }
                </div>
            </div>
        </Layout>
    );
};

export default Results;
