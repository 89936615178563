import { Form, Input, Button, message } from "antd";
import {LockOutlined, MailOutlined} from "@ant-design/icons";
// @ts-ignore
import UseRequireCredentialsModal from "./UseRequireCredentialsModal";
import { ErrorHandler } from "./Shared/ErrorHandler";
import { useHistory } from "react-router-dom";
import { loginUser, useAuthDispatch, useAuthState } from "./Shared/AuthContext";
// @ts-ignore
import logo from "../Resources/new_logo.svg"

const Login = () => {
  const { RequireCredentialModal, toggleRequireCredentialsVisible } =
    UseRequireCredentialsModal();
  const history = useHistory();
  const dispatch = useAuthDispatch();
  const { loading, errorMessage }: any = useAuthState();

  const onFinish = async (data: any) => {
    const path = history.location.pathname.split("/");
    try {
      let response = await loginUser(dispatch, data);

      if (!response) throw response;

      path.length === 4
        ? history.push(
            `/orders/${path[path.length - 2]}/${path[path.length - 1]}`
          )
        : history.push(`/orders`);

      message.success("User logged in successful");
    } catch (error) {
      ErrorHandler(errorMessage);
    }
  };

  return (
    <>
      <RequireCredentialModal />
      <div className="login">
        <Form onFinish={onFinish}>
          <div className="login-main">
            <div className="login-main-top">
              <Form.Item className="login-logo">
                <img src={logo} alt="logo" />
              </Form.Item>

              <Form.Item
                name="username"
                rules={[{ required: true, message: "Username required." }]}
              >
                <Input
                  placeholder="E-mail"
                  size={"large"}
                  type="text"
                  prefix={<MailOutlined style={{ marginRight: "5px" }} />}
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: "Password required." }]}
              >
                <Input.Password
                  placeholder="Password"
                  size={"large"}
                  prefix={<LockOutlined style={{ marginRight: "5px" }} />}
                />
              </Form.Item>
            </div>

            <div className="login-main-bottom">
              <Form.Item>
                <Button htmlType="submit" loading={loading}>
                  LOGIN
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  type="link"
                  style={{ color: "white", border: "white solid 1px" }}
                  onClick={toggleRequireCredentialsVisible}
                >
                  Reset Password
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default Login;
