import React from 'react'
import ReactDOM from 'react-dom'
import Router from './Components/Router'
import './styles/index.scss'
import { AuthProvider } from './Components/Shared/AuthContext';

ReactDOM.render(
    <AuthProvider>
        <Router />
    </AuthProvider>
    , document.getElementById('root'))
