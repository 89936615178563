import {Layout} from "../Shared/Layout";
import {Button, Modal, PageHeader, Select, Table, Typography} from "antd";
import {useEffect, useState} from "react";
import {customer} from "../Shared/Interface";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import useWindowDimensions from "../../Hooks/useWindowDimensions";
import {useParams} from "react-router";
import {CLIENTS_PATH, GET_REQUEST, PUT_REQUEST} from "../Shared/Requests";
import {ErrorHandler} from "../Shared/ErrorHandler";
import {useAuthState} from "../Shared/AuthContext";
import {ascend, descend} from "../Shared/CommonFunctions";

const {Option} = Select;

const ManageClients = () => {
    const h = useHistory();
    const u: any = useAuthState();
    const {width} = useWindowDimensions();
    const {client_id, client_name}: any = useParams();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedClientsForAPI, setSelectedClientsForAPI] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedClientsForTable, setSelectedClientsForTable] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [disableEditPermission, setDisableEditPermission] = useState(false);

    useEffect(() => {
        getClients().then()
        getCustomers().then()
    }, []);

    const getCustomers = async () => {
        setLoading(true);

        GET_REQUEST<customer[]>(`${CLIENTS_PATH}/${u.user.key}`)
            .then((data) => {
                setCustomers(data)
            })
            .catch((err) => ErrorHandler(err.response))
            .finally(() => setLoading(false));
    };

    const getClients = async () => {
        try {
            let res = GET_REQUEST(`clientsPermission/${client_id}`)
            setSelectedClientsForAPI(await res)
            setSelectedClientsForTable(await res)
        } catch (e) {
            setSelectedClientsForAPI([])
            setSelectedClientsForTable([])
            setDisableEditPermission(true)
            ErrorHandler(e.response);
        }
    }

    const columns: any = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            showSorterTooltip: false,
            defaultSortOrder: localStorage.getItem("cs") || "ascend",
            sorter: (a, b, c) => {
                localStorage.setItem("cs", c)
                return c === "ascend" ? ascend(a, b, "name") : descend(a, b, "name")
            },
        }
    ]

    const handleChange = (keys: []) => {
        setSelectedClientsForAPI(keys)
    }

    const handleOk = async () => {
        try {
            let res = await PUT_REQUEST(`/clientsPermission/${client_id}`, selectedClientsForAPI)

            setIsModalOpen(false)
            setSelectedClientsForTable(selectedClientsForAPI)
        } catch (e) {
            setSelectedClientsForAPI([])
            ErrorHandler(e.response);
        }
    }

    return (
        <Layout>
            <div id="user">
                <div className="admin-title">
                    <PageHeader
                        style={{padding: 0}}
                        title={
                            <div
                                style={{float: "left", marginBottom: 0, display: "flex", alignItems: "center"}}
                            >
                                <ArrowLeftOutlined
                                    style={{fontSize: "1rem", marginRight: "1rem"}}
                                    onClick={() => h.goBack()}
                                />
                                <Typography.Title
                                    level={width < 1024 ? 5 : 3}
                                    style={{margin: 0}}
                                    ellipsis={client_name.length > 30 ? {
                                        suffix: "...",
                                    } : false}
                                >
                                    {client_name.length >= 30 ? client_name.slice(0, (30)).trim() : client_name}
                                </Typography.Title>
                                <Typography.Title
                                    level={width < 1024 ? 4 : 3}
                                    style={{margin: 0}}
                                >
                                    's permissions
                                </Typography.Title>
                            </div>
                        }
                    />
                    <div>
                        <Button className="fixed-button"
                                size={"large"}
                                onClick={() => setIsModalOpen(true)}
                                disabled={disableEditPermission}
                        >
                            Edit permission
                        </Button>
                    </div>

                </div>

                <Table size={"middle"} dataSource={customers.filter((c) => selectedClientsForTable.includes(c.key))}
                       columns={columns} loading={false}
                       style={{marginTop: "25px"}}/>

            </div>

            <Modal title="Manage Client Permisions"
                   open={isModalOpen}
                   onOk={handleOk}
                   onCancel={() => {
                       setIsModalOpen(false)
                       setSelectedClientsForAPI([...selectedClientsForTable])
                   }}
                   maskClosable={false}
                   okText={"Save"}
            >
                <p><b>You can add or remove permission to visualize other clients orders for </b><i>{client_name}</i>
                </p>
                <Select
                    allowClear
                    className={"permission-select"}
                    mode={"multiple"}
                    style={{width: "100%"}}
                    onChange={handleChange}
                    value={selectedClientsForAPI}
                    showSearch
                    filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                    {customers.map((c) => (
                        c.key !== client_id && <Option key={c.key} name={c.name}>{c.name}</Option>
                    ))}
                </Select>
            </Modal>
        </Layout>
    );
};

export default ManageClients;
