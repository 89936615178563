import {Button, Input, Table, Tooltip, Typography} from "antd";
import {SolutionOutlined, UserSwitchOutlined} from "@ant-design/icons";
import {ascend, descend,} from "../Shared/CommonFunctions";
import useWindowDimensions from "./../../Hooks/useWindowDimensions";
import {useHistory} from "react-router-dom";
import {useMemo, useState} from "react";

const Clients = ({originalDS, loading}) => {
    const history = useHistory();
    const {width} = useWindowDimensions();
    const [filter, setFilter] = useState("");


    const columns: any = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            showSorterTooltip: false,
            defaultSortOrder: localStorage.getItem("cs") || "ascend",
            sorter: (a, b, c) => {
                localStorage.setItem("cs", c)
                return c === "ascend" ? ascend(a, b, "name") : descend(a, b, "name")
            },
            render: (d, a) => <>{d} <b>({a.external_key})</b></>
        },
        {
            title: "Action",
            width: "7%",
            render: (data) => (
                <div style={{display: "flex"}}>
                    <Tooltip placement="topRight" title={"Manage Users"}>
                        <Button
                            type={"link"}
                            style={{color: "grey", padding: "0", width: "100%"}}
                            onClick={() =>
                                history.push(`/admin/client/${data.key}/${data.name}`)
                            }
                        >
                            <UserSwitchOutlined style={{margin: "auto"}}/>
                        </Button>
                    </Tooltip>
                    <Tooltip placement="topLeft" title={"Additional Permission"}>
                        <Button
                            type={"link"}
                            style={{color: "grey", padding: "0", width: "100%"}}
                            onClick={() =>
                                history.push(`/admin/manage_client/${data.key}/${data.name}`)
                            }
                        >
                            <SolutionOutlined style={{margin: "auto"}}/>
                        </Button>
                    </Tooltip>
                </div>
            ),
            key: "id",
        },
    ];

    const dataSource = useMemo(() => {
        return originalDS.filter(
            (item) => item.name.toLowerCase().includes(filter.toLowerCase())
                || item.external_key.toLowerCase().includes(filter.toLowerCase()))
    }, [originalDS, filter]);

    return (
        <>
            {/* <Divider /> */}
            <div className="admin-title">
                <div style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    paddingBottom: "10px"
                }}>
                    <Typography.Title
                        level={width < 1024 ? 3 : 2}
                        style={{float: "left", flex: 5, marginBottom: 0}}
                    >
                        Clients List
                    </Typography.Title>
                    <Input style={{flex: width < 1024 ? 4 : 2}} type="text"
                           placeholder={"Enter a client name to search"}
                           onInput={(e: any) => setFilter(e.target.value)}/>
                </div>

            </div>

            <>
                <Table
                    size={"middle"}
                    rowKey={"key"}
                    dataSource={dataSource}
                    columns={columns}
                    loading={loading}
                    sortDirections={['ascend', 'descend']}
                />
            </>
        </>
    );
};

export default Clients;
