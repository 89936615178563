import {useEffect, useState} from "react";
import {customer} from "../Shared/Interface";
import {ErrorHandler} from "../Shared/ErrorHandler";
import {Layout} from "../Shared/Layout";
import {
    CLIENTS_PATH,
    GET_REQUEST,
} from "../Shared/Requests";
import Clients from "./Clients";
import {useAuthState} from "../Shared/AuthContext";

/**
 * The main component of the admin component.
 * In here we have Customers, ItemTypes and Orders as child components
 * @returns {React.FC}
 */
const Admin = () => {
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(false);
    const u: any = useAuthState();

    /**
     * This useEffect do 3 get requests to get data for child components
     */
    useEffect(() => {
        try {
            getCustomers().then();
        } catch (error) {
            ErrorHandler(error.response);
        }
    }, [u]);

    /**
     * In this async function get the customers and set the state
     */
    const getCustomers = async () => {
        setLoading(true);

        GET_REQUEST<customer[]>(`${CLIENTS_PATH}/${u.user.key}`)
            .then((data) => {
                setCustomers(data)
            })
            .catch((err) => ErrorHandler(err.response))
            .finally(() => setLoading(false));
    };

    return (
        <Layout>
            <Clients
                key="user"
                originalDS={customers}
                loading={loading}
            />
        </Layout>
    );
};

export default Admin;
